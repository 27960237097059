<template>
  <span :class="`${percentageValue > 0 ? 'text-green-400' : 'text-red-400'} tabular-nums`">
    {{ percentage(rounded(percentageValue, getMaxDecimalPlaces)) }}
  </span>
</template>
<script setup>
import { percentage, rounded } from '@/composeables/filters';
import { computed } from 'vue';

const props = defineProps({
  params: Object
});

const percentageValue = computed(() => {
  if (!props.params.value) {
    return null;
  }
  const doNotMultiply = props.params?.multiply === false || false;
  return doNotMultiply ? props.params.value : props.params.value * 100;
});

const getMaxDecimalPlaces = computed(() => {
  return percentageValue.value ? (Math.abs(percentageValue.value) < 0.0001 ? 6 : 2) : 0;
});
</script>
